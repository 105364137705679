<script setup lang="ts">
const props = defineProps<{
	idBanner: number;
	imgDesk: string;
	imgMob: string;
	bgDesk: string;
	bgMob: string;
	isWelcomeOffer?: boolean;
	badgeLabel?: string;
	title?: string;
	text?: string;
	entries: number;
	coins: number;
	money: string | number;
	imageBadge?: string;
	fetchPriority?: boolean;
}>();

const { t } = useT();
const { data: appInit } = useAppInitData();
const { isMobile } = useDevice();

const badgeLabel = computed(() => props?.badgeLabel && parseJSON(props.badgeLabel));

const handleBannerClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "Your Personal Offer",
		quantity: appInit.value?.depositsCount ?? 0
	});
	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "slider",
		step: "view_payments_info"
	});
	window?.$cash?.$store?.dispatch?.("cash/setOfferType", "promoOffer");
	window?.$cash?.$store?.dispatch?.("cash/setOfferId", props?.idBanner);
	window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${props?.idBanner}/promoOffer/`);
};

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
</script>

<template>
	<MBanner
		:image="`${baseImageUrl}${imgDesk}`"
		:image-mobile="`${baseImageUrl}${imgMob}`"
		:background="`${baseImageUrl}${bgDesk}`"
		:background-mob="`${baseImageUrl}${bgMob}`"
		:welcome-banner="isWelcomeOffer"
		:fetchPriority="fetchPriority"
		:class="['banner', { welcome: isWelcomeOffer }]"
		@click="handleBannerClick"
	>
		<template #badge>
			<div v-if="badgeLabel && !isWelcomeOffer" class="badge">
				<NuxtImg
					v-if="imageBadge"
					:src="`${baseImageUrl}${imageBadge}`"
					format="avif"
					width="160"
					height="160"
					class="badge-img"
					alt="promo-badge"
				/>
				<div class="badge-content">
					<AText variant="taiz" as="div" :modifiers="['bold']" class="percent">{{ badgeLabel?.percent }}</AText>
					<AText :variant="isMobile ? 'tanta' : 'tanzay'" as="div" :modifiers="['uppercase']" class="text">{{
						badgeLabel?.text
					}}</AText>
				</div>
			</div>
		</template>
		<div v-if="props?.isWelcomeOffer && props?.text" v-html="text" />
		<div class="promo-offer">
			<AText v-if="title" variant="tanzay" as="h2" :modifiers="['bold']" class="title">{{ title }}</AText>
			<div>
				<div v-if="entries" class="value">
					<ASvg name="entries" class="icon" />
					<AText variant="tashkent" :modifiers="['bold']" class="text">{{ numberFormat(entries as number) }}</AText>
					<AText variant="tuzla" :modifiers="['uppercase']" class="for-free">{{ t("for free") }}</AText>
				</div>
				<div v-if="coins" class="value">
					<ASvg name="coins" class="icon" />
					<AText variant="tashkent" :modifiers="['bold']" class="text">
						{{ numberFormat(coins as number) }}
					</AText>
				</div>
			</div>
		</div>
		<AButton v-if="money" class="desktop" variant="primary" size="xl"
			>{{ t("Only") }} {{ appInit?.currencySymbol }}{{ money }}</AButton
		>
		<template #button>
			<AButton v-if="money" class="mobile" variant="primary" size="xl"
				>{{ t("Only") }} {{ appInit?.currencySymbol }}{{ money }}</AButton
			>
		</template>
	</MBanner>
</template>

<style scoped lang="scss">
.banner {
	display: inline-flex;
	flex-shrink: 0;
	width: 674px;
	height: 360px;
	cursor: pointer;

	@media (max-width: 1300px) {
		width: 100%;
	}

	@include media-breakpoint-down(md) {
		height: 410px;
	}
}

.flex-column {
	display: flex;
	flex-direction: column;
}
.welcome {
	&:deep(.header) {
		@extend .flex-column;
		justify-content: center;

		@include media-breakpoint-up(md) {
			gap: gutter(0.5);
		}

		.title {
			font-size: 40px;
			line-height: 46px;
			font-weight: 700;
			text-transform: uppercase;

			@include media-breakpoint-up(lg) {
				font-size: 54px;
				line-height: 64px;
			}
		}

		.subtitle {
			@extend .flex-column;

			align-items: center;
			font-weight: 700;
			font-size: 24px;
			line-height: 26px;
			text-transform: none;

			> span {
				text-transform: uppercase;
			}

			@include media-breakpoint-up(md) {
				flex-direction: row;
				gap: gutter(0.5);
			}

			@include media-breakpoint-up(lg) {
				flex-direction: column;
				align-items: flex-start;
				font-size: 38px;
				line-height: 50px;
				text-transform: uppercase;
			}

			@media (min-width: 1130px) {
				flex-direction: row;
			}
		}
	}
}

.promo-offer {
	display: flex;
	flex-direction: column;
	gap: gutter(1.5);

	@include media-breakpoint-down(md) {
		align-items: center;
		gap: gutter(1);
	}

	.title {
		@include media-breakpoint-down(md) {
			font-size: 20px;
			line-height: 26px;
		}
	}

	.value {
		display: flex;
		align-items: center;
		gap: gutter(0.5);

		.text {
			line-height: 56px;

			@include media-breakpoint-down(md) {
				font-size: 40px;
				line-height: 1;
				text-shadow:
					0 4px 6px rgba(var(--corlu-rgb), 0.05),
					0 10px 15px rgba(var(--corlu-rgb), 0.1);
			}
		}
	}

	.for-free {
		width: 40px;
		line-height: 18px;
		@include media-breakpoint-down(md) {
			font-weight: 600;
			text-shadow:
				0 4px 6px rgba(var(--corlu-rgb), 0.05),
				0 10px 15px rgba(var(--corlu-rgb), 0.1);
		}
	}

	.welcome & {
		display: none;

		@include media-breakpoint-down(md) {
			display: flex;
		}
	}
}

.badge {
	display: block;
	position: absolute;
	right: 20px;
	bottom: -19px;
	width: 160px;
	height: 160px;

	@include media-breakpoint-down(md) {
		width: 100px;
		height: 100px;
		top: 108px;
		right: -8px;
	}

	.badge-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -56%) rotate(21deg);

		@include media-breakpoint-down(md) {
			transform: translate(-50%, -56%) rotate(21deg);
		}
	}

	.badge-img {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) rotate(342deg);
	}

	.percent {
		line-height: 1;
		text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

		@include media-breakpoint-down(md) {
			font-size: 25px;
		}
	}

	.text {
		line-height: 1;
	}
}

.icon {
	font-size: 32px;
}
</style>
